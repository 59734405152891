
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IOrder } from "@/types/order";
import { ISupplierStaffListRequest } from "@/types/supplier_staff";
import {
  IInvoice,
  IInvoiceCalculation,
  IInvoiceListRequest
} from "@/types/invoice";

@Component({
  components: {}
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // invoice
  @Action("invoice/invoiceOnlyGet")
  public getInvocie!: (id: number) => void;

  @Action("invoice/invoiceOnlyInvoiceCalculation")
  public getInvoiceCalculation!: (request: IInvoiceListRequest) => void;

  @Getter("invoice/single")
  public invoice!: IInvoice;

  @Getter("invoice/invoice_calculation")
  public invoiceCalculationResult!: IInvoiceCalculation;

  @Mutation("invoice/clear")
  public clearInvoice!: () => void;

  // パラメータ定義
  public params: IInvoiceListRequest = {
    invoice_id: 0
  };

  // fields
  public invoice_id = 0;
  public supplier_id = 0;
  public supplierStaffParams: ISupplierStaffListRequest = {};
  public supplier_staffs = {};

  public async created() {
    this.invoice_id = Number(this.$route.params.invoice_id);
    this.params.invoice_id = this.invoice_id;
    this.clearInvoice();

    await Promise.all([
      this.getInvocie(this.invoice_id),
      this.getInvoiceCalculation(this.params)
    ]);
  }

  public postalcode_insert(val: string) {
    return val ? "〒" + val.slice(0, 3) + "-" + val.slice(3, val.length) : "";
  }

  public tax_calc(val: IOrder) {
    if (!val.tax_rate) {
      return;
    }
    return (val.subtotal * (val.tax_rate / 100)).toFixed(2).toLocaleString();
  }

  public shipment_headers = [
    {
      text: "顧客注文番号",
      value: "received_order_no",
      sortable: false
    },
    {
      text: "出荷日",
      value: "shipment_date",
      sortable: false
    },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },
    { text: "ロットNo.", value: "lot_no", sortable: false },
    {
      text: "数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },

    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    }
  ];

  public invoice_product_headers = [
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },
    { text: "請求商品メモ", value: "memo", sortable: false },

    {
      text: "数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    }
  ];

  get setShipmentData() {
    return this.invoice.shipment.map(el => ({
      received_order_no: el.received_order_no,
      shipment_date: this.omitAD(el.shipment_date),
      fixed_delivery_date: el.fixed_delivery_date,
      shipment_status: el.shipment_status,
      product_shipment: el.product_shipment
    }));
  }

  get setInvoiceProductData() {
    return this.invoice.invoice_product.map(el => ({
      product_name: el.product_name,
      product_code: el.product_code,
      customer_pn: el.customer_pn,
      quantity: Number(el.quantity),
      buying_unit_price: Number(el.buying_unit_price),
      selling_unit_price: Number(el.selling_unit_price),
      memo: el.memo
    }));
  }

  // subtotal
  public subtotal() {
    const expense = this.invoice.invoice_price_tag.reduce(function (
      previous,
      current
    ) {
      if (current.is_minus == true) {
        return current.price ? previous - current.price : previous;
      }
      return current.price ? previous + current.price : previous;
    },
    0);
    return this.invoice.sell_subtotal + expense;
  }

  // tax amount calc
  public taxAmount() {
    return Number((this.subtotal() * (this.invoice.tax_rate / 100)).toFixed(2));
  }
}
